import React, { useState, useRef, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Ollama } from 'ollama/browser';
import './Chat.css';

const Chat = () => {
  const [messageList, setMessageList] = useState([]);
  const [input, setInput] = useState('');
  const [fetching, setFetching] = useState(false);
  const abortControllerRef = useRef(null);
  const textareaRef = useRef(null);
  const urlAddress = 'http://ai.roboteman.com'; //http://ai.roboteman.com  localhost 176.124.202.87
  const ollama = new Ollama({ host: urlAddress + ':11434' });
  const modelName = 'llama3.2:1b'; // llama3.2:3b llama3.2:latest

  useEffect(() => {
    textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
  }, [messageList]);

  const fetchResponse = async () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      
      setFetching(true);

      const userMessage = {
        role: 'user',
        content: input,
      };

      const newMessageList = [...messageList, userMessage];
      setMessageList(newMessageList);

      const stream = await ollama.chat({
        model: modelName,
        messages: newMessageList,
        options: {
            "num_keep": 4,
            //"seed": 122,
            "num_predict": 64,
            //"top_k": 50,
            //"top_p": 0.9,
            //"tfs_z": 0.5,
            //"typical_p": 0.7,
            //"repeat_last_n": 5,
            //"temperature": 0.8,
            //"repeat_penalty": 1.7,
            //"presence_penalty": 1.5,
            //"frequency_penalty": 1.0,
            //"mirostat": 1,
            //"mirostat_tau": 0.8,
            //"mirostat_eta": 0.6,
            //"penalize_newline": true,
            //"stop": ["\n"],
            //"numa": false,
            //"num_ctx": 128,
            //"num_batch": 1,
            "low_vram": false,
            //"f16_kv": true,
            //"vocab_only": true,
            //"use_mmap": false,
            //"use_mlock": true,
            //"rope_frequency_base": 1.1,
            //"rope_frequency_scale": 0.8
        },
        stream: true
      });
      
      const assistantMessage = {
        role: 'assistant',
        content: '',
      };
      for await (const chunk of stream) {
        if (abortController.signal.aborted) break;
        assistantMessage.content += chunk.message.content

        const updatedMessageList = [...newMessageList, assistantMessage];
        setMessageList(updatedMessageList);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('An error occurred:', error);
        const errorMessage = {
          role: 'system',
          content: `Error: ${error.message}`,
        };
        const updatedMessageList = [...messageList, errorMessage];
        setMessageList(updatedMessageList);
      }
    } finally {
      setFetching(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInput('');
    await fetchResponse();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent default behavior (adding newline)
      handleSubmit(event);
    }
  };

  const handleAbort = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setFetching(false);
      console.log('The request has been aborted');
    }
  };

  return (
    <Container className="chat-container" style={{ fontFamily: 'Ubuntu, Tahoma'}}>
      <h1>💻 Mehrnoosh 🤍 App </h1>
      <div ref={textareaRef} className="chat-textarea" style={{ fontFamily: 'Ubuntu, Tahoma', flex: 1 }}>
        {messageList.map((message, index) => (
          <div key={index} className={message.role === 'user' ? 'message-right' : 'message-left'}>
            {message.content}
          </div>
        ))}
      </div>
      <Form onSubmit={handleSubmit} className="chat-form">
        <Form.Control
          as="textarea"
          rows={3}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type a message"
          className="chat-input"
          style={{ fontFamily: 'Ubuntu, Tahoma'}}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="submit" className="chat-button" disabled={fetching} style={{ fontFamily: 'Ubuntu, Tahoma' }}>
            Send
          </Button>
          <Button className="abort-button" onClick={handleAbort} disabled={!fetching} style={{ fontFamily: 'Ubuntu, Tahoma' }}>
            Abort
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default Chat;
