// App.js

import React, { useState } from 'react';
import './App.css';
import Chat from './Chat';
import Login from './Login'; // Import the Login component

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = () => {
    // Implement your authentication logic here
    // For simplicity, you can hardcode the username and password
    // Replace 'your_username' and 'your_password' with actual values
    const username = 'your_username';
    const password = 'your_password';

    // Check if the username and password match
    if (username === 'your_username' && password === 'your_password') {
      setLoggedIn(true);
    } else {
      alert('Invalid username or password');
    }
  };

  return (
    //<div className="App">
    //{!loggedIn && <Login onLogin={handleLogin} />}
    //{ loggedIn && <Chat />}
    //</div>

    <div className="App">
      <Chat />
    </div >  
    
  );
}

export default App;
